import { writable } from 'svelte/store';

const workspaceStore = writable<{
  workspaceId: string;
}>();

export default {
  subscribe: workspaceStore.subscribe,
  set: workspaceStore.set,
};
