import * as authApi from '$lib/api/auth';
import type { UserSettingsRequest } from '$lib/types/request';
import type { TaigaUserResponse, UserSettingsResponse } from '$lib/types/response';

export const getTaigaCurrentUser = async (): Promise<TaigaUserResponse> => {
  return (await authApi.getTaigaCurrentUser()).data;
};

export const updateTaigaCurrentUser = async (settings: UserSettingsRequest): Promise<UserSettingsResponse> => {
  return (await authApi.updateTaigaCurrentUser(settings)).data;
};
